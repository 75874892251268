import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { rootReducer as reviewReducer } from '../resources/reviewResources';
import { rootReducer as locationReducer } from '../resources/locationsResources';
import { rootReducer as competitor } from '../resources/competitors/competitors';
import { rootReducer as competitorsReport } from '../resources/competitors/competitorsReports';
import { rootReducer as reviewsAndRatings } from '../resources/reviewsAndRatings/reviewsAndRatings';
import { rootReducer as pagesConversations } from '../resources/messages/pagesConversationsResources';
import { rootReducer as competitorsBrand } from '../resources/competitors/competitorsBrands';
import { rootReducer as competitorFileFields } from '../resources/competitors/competitorFileFields';
import { rootReducer as templateReducer } from '../resources/templateResources';
import { rootReducer as responseReducer } from '../resources/responseResources';
import { rootReducer as brandsReducer } from '../resources/brandsResources';
import { rootReducer as listingManagementReducer } from '../resources/listingManagement/listingManagementResources';
import { rootReducer as categoriesReducor } from '../resources/categoriesResources';
import { rootReducer as rankingReducer } from '../resources/rankingRecources';
import { rootReducer as notificationsReducer } from '../resources/notificationsRecources';
import { rootReducer as averageRatingReducer } from '../resources/reports/averageRatingResource';
import { rootReducer as reviewsVolume } from '../resources/reports/reviewsVolumeResource';
import { rootReducer as teamUserReducer } from '../resources/team_access/teamAccessResources';
import { rootReducer as inviteUserReducer } from '../resources/team_access/inviteUserResources';
import { rootReducer as invitationReducer } from '../../appRedux/resources/team_access/invitationResources';
import { rootReducer as brandStateLocationsReducor } from '../resources/brandStateLocationsResources';
import { rootReducer as improvedLocations } from '../resources/reports/improvedLocations';
import { rootReducer as lowestReviews } from '../resources/reports/lowestReviews';
import { rootReducer as averageTrands } from '../resources/reports/trends';
import { rootReducer as performingLocations } from '../resources/reports/performingLocations';
import { rootReducer as lowPerformingLocations } from 'appRedux/resources/reports/lowPerformingLocations';
import { rootReducer as currentUserReducer } from '../resources/currentUser/currentUserResources';
import { rootReducer as userFilters } from '../resources/currentUser/userFilters';
import { rootReducer as companiesReducor } from '../resources/companies/companiesResources';
import { rootReducer as widgetsReducor } from '../resources/companies/widgetsResources';
import { rootReducer as companyBrand } from '../resources/companies/companyBrandResources';
import { rootReducer as responsesRateReducer } from '../resources/responsesRate/responsesRateResources';
import { rootReducer as reportSettingsReducer } from '../resources/notifications/reportSettings';
import { rootReducer as companySourcesReducer } from '../resources/companySources/companySourcesResources';
import { rootReducer as responseLanguages } from '../resources/responsLanguages/responseLanguages';
import { rootReducer as forgotPasswordMailReducer } from '../resources/forgotPassword/forgotPassword';
import { rootReducer as confirmPasswordReducer } from '../resources/forgotPassword/confirmPassword';
import { rootReducer as reviewCategoryReducer } from '../resources/reviewCategory/reviewCategory';
import { rootReducer as uploadLocationsStructure } from '../resources/locations/uploadLocationsStructure';
import { rootReducer as uploadReviewsStructure } from '../resources/reviews/uploadReviewsStructure';
import { rootReducer as shortInfoLocations } from '../resources/locations/shortInfoLocations';
import { rootReducer as landingPageLocationReducer } from '../resources/landingPage/landingPageLocations';
import { rootReducer as landingPageBrandReducer } from '../resources/landingPage/landingPageBrand';
import { rootReducer as landingPageSettingsReducer } from '../resources/landingPage/LandingPageSettings';
import { rootReducer as otherLoginReducer } from '../resources/otherLogin/getOtherLogins/otherLogin';
import { rootReducer as serviceCredentialsReducer } from '../resources/otherLogin/serviceCredentials';
import { rootReducer as integrations } from '../resources/otherLogin/integrations/index';
import { rootReducer as locationsAccess } from '../resources/otherLogin/locationsAccess/locationsAccess';
import { rootReducer as connectedLocation } from '../resources/otherLogin/connectedLocation/connectedLocation';
import { rootReducer as facebookApi } from '../resources/otherLogin/facebookApi';
import { rootReducer as mailChimp } from '../resources/otherLogin/mailChimp';
import { rootReducer as activeCampaign } from '../resources/otherLogin/activeCampaign';
import { rootReducer as zendesk } from '../resources/otherLogin/zendesk';
import { rootReducer as jira } from '../resources/otherLogin/jira';
import { rootReducer as deleteOtherAccountReducer } from '../resources/otherLogin/deleteOtherLogin/deleteOtherLogin';
import { rootReducer as landingPageStatisticReducer } from '../resources/landingPage/LandingPageStatistic';
import { rootReducer as googlCredentialsForReplyReducer } from '../resources/reviews/googlCredentialsForReply';
import { rootReducer as facebookCredentialsForReplayReducer } from '../resources/reviews/facebookCredentialsForReplay';
import { rootReducer as reviewTranslationReducer } from '../resources/reviews/reviewTranslation';
import { rootReducer as reviewForExportReducer } from '../resources/reviews/reviewForExport';
import { rootReducer as userSettingsReducer } from '../resources/currentUser/userSettings';
import { rootReducer as packagesReducer } from '../resources/companies/packages';
import { rootReducer as caseResolution } from '../resources/caseResolution';
import { rootReducer as adminUsers } from '../resources/adminUsers/adminUsers';
import { rootReducer as partners } from '../resources/partners/partners';
import { rootReducer as partnerUsers } from '../resources/partners/partnersUsers';
import { rootReducer as userAsClient } from '../resources/currentUser/userAsClient';
import { rootReducer as autoResponseSettings } from '../resources/autoResponseSettings';
import { rootReducer as csdashboard } from '../resources/csdashboard/csdashboard';
import { rootReducer as csdashboardResponse } from '../resources/csdashboard/response';
import { rootReducer as csdashboardFeedback } from '../resources/csdashboard/feedback';
import { rootReducer as csdashboardOAuth } from '../resources/csdashboard/oAuth';
import { rootReducer as conversationReducer } from '../resources/messages/conversationResources';
import { rootReducer as messageReducer } from '../resources/messages/messageResources';
import { rootReducer as pagesReducer } from '../resources/messages/pagesResources';
import { rootReducer as tagsReducer } from '../resources/tagsResources';
import { rootReducer as tagsMessagesReducer } from '../resources/tagsMessageResources';
import { rootReducer as socialPostReducer } from '../resources/socialPostResources';
import { rootReducer as postCommentReducer } from '../resources/postCommentResources';
import { rootReducer as messagesInfo } from '../resources/messages/messagesInfoResources';
import { rootReducer as messagesAnalytics } from '../resources/messages/messagesAnalyticsResources';
import { rootReducer as messageTranslationReducer } from '../resources/messages/messageTranslationResources';
import { rootReducer as twitterApi } from '../resources/otherLogin/twitterApi';
import { rootReducer as insightsPrimaryReducer } from '../resources/insightsPrimaryResources';
import { rootReducer as insightsSecondaryReducer } from '../resources/insightsSecondaryResources';
import { rootReducer as insihgtsWordsReducer } from '../resources/insightsWordsResources';
import { rootReducer as insightsTrendReducer } from '../resources/insightsTrendResources';
import { rootReducer as webhookReducer } from '../resources/webhookResources';
import { rootReducer as systemHealthReducer } from '../resources/systemHealth/systemHealthResources';
import { rootReducer as systemHealthCompanyReducer } from '../resources/systemHealth/systemHealthCompaniesResources';
import { rootReducer as systemHealthLocationsReducer } from '../resources/systemHealth/systemHealthLocationsResources';
import { rootReducer as locationsPerformanceReducer } from '../resources/systemHealth/locationsPerformanceResources';
import { rootReducer as reactionReducer } from '../resources/reactionResources';
import { rootReducer as chatGptReducer } from '../resources/chatGptResources';
import { rootReducer as reputationScoreReducer } from '../resources/dashboard/reputationScoreResources';
import { rootReducer as netPromoterScoreReducer } from '../resources/dashboard/netPromoterScoreResources';
import { rootReducer as feedbackReducer } from '../resources/dashboard/feedbackResources';
import { rootReducer as otherReducer } from '../resources/dashboard/otherResources';
import { rootReducer as premiumReducer } from '../resources/dashboard/premiumResources';
import { rootReducer as offerReducer } from '../resources/offerResources';
import Settings from './Settings';
import Auth from './Auth';
import Pagination from './Pagination';
import Dashboard from './Dashboard';
import DashboardChart from './DashboardChart';
import paginationLocations from './LocationsPagination';
import paginationCompanies from './CompaniesPagination';
import paginationTemplates from './TemplatesPagination';
import paginationTeamUsers from './TeamUsersPagination';
import paginationLandingPageLocation from './PaginationLandingPageLocation';
import paginationLandingPageBrands from './PaginationLandingPageBrands';

const mainReducers = {
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  competitors: competitor,
  locations: locationReducer,
  paginationLocations,
  brands: brandsReducer,
  listingManagement: listingManagementReducer,
  categories: categoriesReducor,
  ranking: rankingReducer,
  notificationsSettings: combineReducers({
    notifications: notificationsReducer,
    reportSettings: reportSettingsReducer
  }),
  reviewsAll: combineReducers({
    reviews: reviewReducer,
    pagination: Pagination,
    googlCredentialsForReply: googlCredentialsForReplyReducer,
    facebookCredentialsForReplay: facebookCredentialsForReplayReducer,
    reviewTranslation: reviewTranslationReducer,
    reviewForExport: reviewForExportReducer
  }),

  reports: combineReducers({
    averageRating: averageRatingReducer,
    reviewsVolume,
    trends: averageTrands,
    topLists: combineReducers({
      improvedLocations,
      lowestReviews,
      performingLocations,
      lowPerformingLocations
    })
  }),
  team_users: combineReducers({
    invitation: invitationReducer,
    currentUser: currentUserReducer,
    userFilters: userFilters,
    userSettings: userSettingsReducer,
    teamUsers: teamUserReducer,
    paginationTeamUsers,
    userAsClient: userAsClient
  }),
  invitation: invitationReducer,
  inviteUser: inviteUserReducer,
  brandStateLocations: brandStateLocationsReducor,
  forgotPasswordEmail: forgotPasswordMailReducer,
  confirmPassword: confirmPasswordReducer,
  admin: combineReducers({
    companies: companiesReducor,
    paginationCompanies: paginationCompanies,
    widgets: widgetsReducor,
    companyBrands: companyBrand,
    packages: packagesReducer,
    adminUsers: adminUsers,
    partners: partners,
    partnerUsers: partnerUsers,
    csdashboard: csdashboard,
    csdashboardResponse: csdashboardResponse,
    csdashboardFeedback: csdashboardFeedback,
    csdashboardOAuth: csdashboardOAuth
  }),
  allTemplates: combineReducers({
    template: templateReducer,
    paginationTemplates: paginationTemplates
  }),
  landingPage: combineReducers({
    landingPageLocation: landingPageLocationReducer,
    landingPageBrands: landingPageBrandReducer,
    paginationLandingPageLocation,
    paginationLandingPageBrands,
    landingPageSettings: landingPageSettingsReducer,
    landingPageStatistic: landingPageStatisticReducer
  }),
  responsesRate: responsesRateReducer,
  companySources: companySourcesReducer,
  responseLanguages: responseLanguages,
  dashboard: Dashboard,
  dashboardChart: DashboardChart,
  reviewCategories: reviewCategoryReducer,
  caseResolutions: caseResolution,
  uploadLocationsStructure: uploadLocationsStructure,
  uploadReviewsStructure: uploadReviewsStructure,
  shortInfoLocations: shortInfoLocations,
  competitorsReports: competitorsReport,
  competitorsBrands: competitorsBrand,
  competitorFileFields: competitorFileFields,
  reviewsAndRatings: reviewsAndRatings,
  pagesConversations: pagesConversations,
  serviceCredentials: serviceCredentialsReducer,
  otherLogins: combineReducers({
    otherLoginsInfo: otherLoginReducer,
    deleteOtherAccount: deleteOtherAccountReducer,
    locationsAccess: locationsAccess,
    connectedLocation: connectedLocation,
    integrations,
    facebookApi,
    mailChimp,
    activeCampaign,
    zendesk,
    jira,
    twitterApi
  }),
  autoResponseSettings: autoResponseSettings,
  conversation: conversationReducer,
  response: responseReducer,
  message: messageReducer,
  pages: pagesReducer,
  tags: tagsReducer,
  reaction: reactionReducer,
  socialPost: socialPostReducer,
  commentPost: postCommentReducer,
  messagesInfo: messagesInfo,
  messagesAnalytics: messagesAnalytics,
  tagsmessage: tagsMessagesReducer,
  messageTranslation: messageTranslationReducer,
  insightsPrimary: insightsPrimaryReducer,
  insightsSecondary: insightsSecondaryReducer,
  insightsWords: insihgtsWordsReducer,
  insightsTrends: insightsTrendReducer,
  webhookSettings: webhookReducer,
  systemHealth: systemHealthReducer,
  systemHealthCompany: systemHealthCompanyReducer,
  systemHealthLocation: systemHealthLocationsReducer,
  locationsPerformance: locationsPerformanceReducer,
  chatGbtResponse: chatGptReducer,
  offers: offerReducer,
  reputationScore: reputationScoreReducer,
  netPromoterScore: netPromoterScoreReducer,
  feedbackResponse: feedbackReducer,
  otherResponse: otherReducer,
  premiumResponse: premiumReducer
};

const sortedReducers = Object.keys(mainReducers)
  .sort()
  .reduce((acc, cur) => ({ ...acc, [cur]: mainReducers[cur] }), {});

const reducers = combineReducers(sortedReducers);
const rootReducers = (state, action) => {
  if (action.type === 'SIGNOUT_USER_SUCCESS') {
    state = undefined;
  }
  if (action.type === '@@resource/INVITATION/CREATE') {
    state = undefined;
  }
  return reducers(state, action);
};

export default rootReducers;
