import { createResource } from 'redux-rest-resource';
import { API_URL } from '../../../config';

const url = `${API_URL}v2/api/google/locations`;

export const { types, actions, rootReducer } = createResource({
    name: 'listingManagement',
    url,

    actions: {
        fetch: {
            method: 'GET',
            transformResponse: res => {
                return {
                    res,
                    body: res.body
                };
            }
        }
    }
});
