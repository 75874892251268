const API_URL = process.env.REACT_APP_API_URL || 'https://staging-api.localyser.com/';

const APP_URL = process.env.REACT_APP_APP_URL || 'https://staging-app.localyser.com/';

const isPRODUCTION = process.env.REACT_APP_NODE_ENV === 'production';
const isLOCALHOST = process.env.REACT_APP_NODE_ENV === 'localhost';
const UNDER_MAINTENANCE = false;
const FEEDBACK_API_REFFERENCE = process.env.REACT_APP_FEEDBACK_API_REFFERENCE || 'https://staging.fdbck.me/';
const INFORMATION_REQUEST_URL = process.env.REACT_APP_INFORMATION_REQUEST_URL || 'staging.fdbck.me/';

const INVITE_TEAM_USER = `${APP_URL}invitation/`;
const FORGOT_PASSWORD_URL = `${APP_URL}confirmPassword/`;
const TEST_URL = `${isLOCALHOST ? 'http://localhost:8081/' : APP_URL}`;

const UPLOAD_LOCATIONS = `${API_URL}v2/api/locations/upload`;
const UPLOAD_REVIEWS = `${API_URL}v2/api/reviews/upload`;
const UPLOAD_COUPONS = `${API_URL}v2/api/offer/upload`;
const UPLOAD_TEAM_USERS = `${API_URL}v2/api/team/upload`;
const UPLOAD_COMPETITORS = `${API_URL}v2/api/competitor/upload`;
const EXPORT_LOCATIONS_URL = `${API_URL}v2/api/locations/export/excel?`;
const EXPORT_COMPETITORS_URL = `${API_URL}v2/api/competitor/export/excel?`;
const EXPORT_PARTNERS_URL = `${API_URL}v2/api/partner/excel?`;
const EXPORT_PARTNERS_BILLING = `${API_URL}v2/api/partner/billing/excel`;

const EXPORT_TEAM_ACCESS = `${API_URL}v2/api/team/export/excel?`;
const EXPORT_REVIEWS_URL = `${API_URL}v2/api/reviews/export/excel?`;
const EXPORT_REVIEWS_TOPDF_URL = `${API_URL}v2/api/reviews/export/pdf?`;
const EXPORT_DASHBOARD_URL = `${API_URL}v2/api/dashboard/chart/export/excel?`;
const EXPORT_FEEDBACK_ANALYTICS_URL = `${API_URL}v2/api/lendingPageStatistic/export/excel?`;

const EXPORT_TEMPLATES_URL = `${API_URL}v2/api/templates/export/excel?`;
const EXPORT_RATINGS_AND_REVIEWS = `${API_URL}v2/api/reviews/rating/export/excel?`;
const EXPORT_COMPETITOR_REPORT = `${API_URL}v2/api/competitor/report/export/excel?`;
const EXPORT_RESPONSES_RATE_URL = `${API_URL}v2/api/reviews/response_rate/export/excel?`;
const EXPORT_OVERVIEW_REPORT_URL = `${API_URL}v2/api/statistics/locations/overview/export/excel?`;
const EXPORT_INSIGHTS_REPORT_URL = `${API_URL}v3/api/reviews/insights/export/excel?`;

const EXPORT_CSDASHBOARD_OVERVIEW = `${API_URL}v2/api/admin/dashbord/overview/export/excel?`;
const EXPORT_CSDASHBOARD_RESPONSE = `${API_URL}v2/api/admin/dashbord/response/export/excel?`;
const EXPORT_CSDASHBOARD_FEEDBACK = `${API_URL}v2/api/admin/dashbord/feedback/export/excel?`;
const EXPORT_CSDASHBOARD_OAUTH = `${API_URL}v2/api/admin/dashbord/oauth/export/excel?`;

const EXPORT_MESSAGES_ANALYTICS_URL = `${API_URL}v2/api/conversations/analytics/export/excel?`;
const EXPORT_MESSAGES_PAGES_URL = `${API_URL}v2/api/conversations/pages/export/excel?`;

const EXPORT_LOCATION_RANKING_URL = `${API_URL}v2/api/statistics/locations/improved/export/excel?`;
const EXPORT_LOCATIONS_EXAMPLE_URL = `${API_URL}v2/api/locations/upload/example?`;
const EXPORT_REVIEWS_EXAMPLE_URL = `${API_URL}v2/api/reviews/upload/example?`;
const EXPORT_COUPONS_EXAMPLE_URL = `${API_URL}v2/api/offer/upload/example?`;
const EXPORT_COMPETITORS_EXAMPLE_URL = `${API_URL}v2/api/competitor/download/example?`;
const EXPORT_OFFERS_EXCEL = `${API_URL}v2/api/offers/export/excel?`;
const EXPORT_COUPONS_EXCEL = `${API_URL}v2/api/offers/coupons/export/excel?`;
const GENERATE_COUPONS = `${API_URL}v2/api/offer/generate`;
const CONNECT_TO_GOOGLE_ACCOUNT = `${API_URL}v2/api/admin/googleOAuth/url`;

const HELP_URL = 'https://localyser.atlassian.net/servicedesk/customer/portals';
const EXPORT_COMPANIES_URL = `${API_URL}v2/api/admin/companies/export?`;
const CLIENT_ID = '1_3bcbxd9e24g0gk4swg0kwgcwg4o8k8g4g888kwc44gcc0gwwk4';
const CLIENT_SECRET = '4ok2x70rlfokc8g0wws8c8kwcokw80k44sg48goc0ok4w0so0k';
const INFORMATION_REQUEST_API = `${API_URL}v2/api/reviews/info/`;

const REPLACEMENT_KEYS = [
  {
    dataName: 'firstName',
    keyName: 'First name',
    text: "Will be replaced with the reviewer's first name.  If available."
  },
  {
    dataName: 'companyName',
    keyName: 'Company name',
    text: 'Will be replaced with the name of the company.'
  },
  {
    dataName: 'locationName',
    keyName: 'Location name',
    text: 'Will be replaced with the name of the location.'
  },
  {
    dataName: 'informationRequestURL',
    keyName: 'Information request URL',
    text: 'Will be replaced with the URL for the Customer.'
  }
];

const RATES = ['1', '2', '3', '4', '5'];
const ROLES = ['Master Admin', 'Manager', 'Responder', 'Analyst'];
const API_KEY = 'AIzaSyCqFQAcwX4K2emMQ5783kntnPv5_GXwYkk';
const STATUSES = [
  { title: 'Active', value: 'active' },
  { title: 'Inactive', value: 'inactive' }
];
const SENTIMENT_OPTIONS = [
  {
    value: '1,2',
    title: 'Negative'
  },
  {
    value: '3',
    title: 'Neutral'
  },
  {
    value: '4,5',
    title: 'Positive'
  }
];

const COMPANY_TYPES = [
  {
    company_type: 'Automotive',
    sources: ['Google', 'Facebook', 'Foursquare'],
    hasLocation: true
  },
  {
    company_type: 'Software',
    sources: ['Google', 'Facebook', 'Foursquare'],
    hasLocation: false
  },
  {
    company_type: 'Mobile Apps',
    sources: ['Google', 'Facebook', 'Foursquare'],
    hasLocation: false
  },
  {
    company_type: 'eCommerce',
    sources: ['Google', 'Facebook', 'Foursquare'],
    hasLocation: false
  },
  {
    company_type: 'Banks',
    sources: ['Google', 'Facebook', 'Foursquare'],
    hasLocation: true
  },
  {
    company_type: 'Business Services',
    sources: ['Google', 'Facebook', 'Foursquare'],
    hasLocation: true
  },
  {
    company_type: 'Cafes',
    sources: ['Google', 'Facebook', 'Foursquare', 'Zomato', 'TripAdvisor', 'Talabat', 'HungerStation', 'ReserveOut'],
    hasLocation: true
  },
  {
    company_type: 'Education',
    sources: ['Google', 'Facebook', 'Foursquare'],
    hasLocation: true
  },
  {
    company_type: 'Electronics',
    sources: ['Google', 'Facebook', 'Foursquare'],
    hasLocation: true
  },
  {
    company_type: 'Fashion',
    sources: ['Google', 'Facebook', 'Foursquare'],
    hasLocation: true
  },
  {
    company_type: 'Fitness',
    sources: ['Google', 'Facebook', 'Foursquare'],
    hasLocation: true
  },
  {
    company_type: 'Healthcare',
    sources: ['Google', 'Facebook', 'Foursquare'],
    hasLocation: true
  },
  {
    company_type: 'Home Services',
    sources: ['Google', 'Facebook', 'Foursquare'],
    hasLocation: true
  },
  {
    company_type: 'Hotels',
    sources: ['Google', 'Facebook', 'Foursquare', 'TripAdvisor', 'Booking', 'Expedia'],
    hasLocation: true
  },
  {
    company_type: 'Malls',
    sources: ['Google', 'Facebook', 'Foursquare'],
    hasLocation: true
  },
  {
    company_type: 'Pharmacies',
    sources: ['Google', 'Facebook', 'Foursquare'],
    hasLocation: true
  },
  {
    company_type: 'Restaurants',
    sources: ['Google', 'Facebook', 'Foursquare', 'Zomato', 'TripAdvisor', 'Talabat', 'HungerStation', 'ReserveOut'],
    hasLocation: true
  },
  {
    company_type: 'Spas',
    sources: ['Google', 'Facebook', 'Foursquare'],
    hasLocation: true
  },
  {
    company_type: 'Tourist Attractions',
    sources: ['Google', 'Facebook', 'Foursquare'],
    hasLocation: true
  },
  {
    company_type: 'Wellness',
    sources: ['Google', 'Facebook', 'Foursquare'],
    hasLocation: true
  },
  {
    company_type: 'Other',
    sources: ['Google', 'Facebook', 'Foursquare'],
    hasLocation: true
  }
];
const PARTNERS_TYPE = ['Agency', 'Online Ordering', 'POS System', 'Reservation', 'Social WiFi', 'Telco', 'Other'];

const PARTNERS_PACKAGES = [
  { package: 'Starter', credits: 100000 },
  { package: 'Business', credits: 250000 },
  { package: 'Enterprise', credits: 750000 },
  { package: 'Custom', credits: null }
];

const REVIEW_OPTIONS = [
  { title: 'With text only', value: 0 },
  { title: 'Without text only', value: 1 },
  { title: 'Only with responses', value: 2 },
  { title: 'Only without responses', value: 3 }
];
const RANKS = [1, 2, 3, 4];

const SOURCESWITHLOGIN_NAMES = {
  DELIVEROO: 'Deliveroo',
  CHATFOOD: 'Chatfood',
  UBEREATS: 'Ubereats',
  DOORDASH: 'DoorDash',
  HUNGERSTATION: 'HungerStation'
};

const SOURCESWITHLOGIN = [
  SOURCESWITHLOGIN_NAMES.DELIVEROO,
  SOURCESWITHLOGIN_NAMES.CHATFOOD,
  SOURCESWITHLOGIN_NAMES.UBEREATS,
  SOURCESWITHLOGIN_NAMES.DOORDASH,
  SOURCESWITHLOGIN_NAMES.HUNGERSTATION
];

const USERS = [
  {
    userName: 'Owner',
    password: 'dIVageADyaNH',
    mail: 'owner@springsapps.com'
  },
  {
    userName: 'Springs Test',
    password: '111111',
    mail: 'yuriy.api.test@gmail.com'
  },
  {
    userName: 'Azadea',
    password: 'chaViAbLArMO',
    mail: 'yuriy.gudachenko@springsapps.com'
  },
  {
    userName: 'Eathos',
    password: 'dIVageADyaNH',
    mail: 'yuriy.gudachenkoEdited+1@springsapps.com'
  },
  {
    userName: 'Gourmet Gulf',
    password: 'fiAgoTiDentI',
    mail: 'yuriy.gudachenko+2@springsapps.com'
  },
  {
    userName: 'Meraas',
    password: 'tAFtOtioNanT',
    mail: 'yuriy.gudachenko+3@springsapps.com'
  },
  {
    userName: 'Saleh Bin Lahej',
    password: 'eArbANCiMpYr',
    mail: 'yuriy.gudachenko+4@springsapps.com'
  },
  {
    userName: 'Shakespeare & Co',
    password: 'vIKiWOUsiDer',
    mail: 'yuriy.gudachenko+5@springsapps.com'
  },
  {
    userName: 'Tarfeeh',
    password: 'pROmErIANtrA',
    mail: 'yuriy.gudachenko+6@springsapps.com'
  },
  {
    userName: 'Kate Partner',
    password: 'qwerty12345',
    mail: 'kat.denisenko+11@gmail.com'
  }
];

const INSIGHTS_CATEGORIES_DATA = [
  {
    title: 'Food',
    key: 'food',
    color: '#3B729C',
    children: [
      {
        title: 'Food General',
        key: 'c_food',
        color: '#3B729C',
        fieldName: 'foodGeneral'
      },
      {
        title: 'Food Taste',
        key: 'c_f_taste',
        color: '#8EB7D7',
        fieldName: 'foodTaste'
      },
      {
        title: 'Food Quality',
        key: 'c_f_quality',
        color: '#7AC0F4',
        fieldName: 'foodQuality'
      },
      {
        title: 'Food Quantity',
        key: 'c_f_quantity',
        color: '#5194C7',
        fieldName: 'foodQuantity'
      },
      {
        title: 'Food Variety',
        key: 'c_f_variety',
        color: '#3B729C',
        fieldName: 'foodVariety'
      }
    ]
  },
  {
    title: 'Delivery',
    key: 'delivery',
    color: '#FA8C15',
    children: [
      {
        title: 'Delivery General',
        key: 'c_delivery',
        color: '#FA8C15',
        fieldName: 'deliveryGeneral'
      },
      {
        title: 'Delivery Accuracy',
        key: 'c_d_accuracy',
        color: '#FAAD59',
        fieldName: 'deliveryAccuracy'
      },
      {
        title: 'Delivery Speed',
        key: 'c_d_speed',
        color: '#F19B3F',
        fieldName: 'deliverySpeed'
      },
      {
        title: 'Delivery Packaging',
        key: 'c_d_packaging',
        color: '#D6A05C',
        fieldName: 'deliveryPackaging'
      }
    ]
  },
  {
    title: 'Atmosphere',
    key: 'atmosphere',
    color: '#8567D3',
    children: [
      {
        title: 'Atmosphere General',
        key: 'c_atmosphere',
        color: '#8567D3',
        fieldName: 'atmosphereGeneral'
      },
      {
        title: 'Atmosphere Cleanliness',
        key: 'c_a_cleanliness',
        color: '#9696D2',
        fieldName: 'atmosphereCleanliness'
      },
      {
        title: 'Atmosphere Emotion',
        key: 'c_a_emotion',
        color: '#AB99DC',
        fieldName: 'atmosphereEmotion'
      }
    ]
  },
  {
    title: 'Service',
    key: 'service',
    color: '#818106',
    children: [
      {
        title: 'Service General',
        key: 'c_service',
        color: '#818106',
        fieldName: 'serviceGeneral'
      },
      {
        title: 'Service Friendliness',
        key: 'c_s_friendliness',
        color: '#C7C713',
        fieldName: 'serviceFriendliness'
      },
      {
        title: 'Service Speed',
        key: 'c_s_speed',
        color: '#818106',
        fieldName: 'serviceSpeed'
      }
    ]
  },
  {
    title: 'Price',
    key: 'price',
    color: '#BB016B',
    children: [
      {
        title: 'Price',
        key: 'c_price',
        color: '#BB016B',
        fieldName: 'priceGeneral'
      }
    ]
  },
  {
    title: 'Recommend',
    key: 'recommend',
    color: '#ffdd59',
    children: [
      {
        title: 'Recommend',
        key: 'c_recommend',
        color: '#ffdd59',
        fieldName: 'recommendGeneral'
      }
    ]
  }
];

export {
  SOURCESWITHLOGIN,
  SOURCESWITHLOGIN_NAMES,
  API_URL,
  isPRODUCTION,
  isLOCALHOST,
  CLIENT_ID,
  CLIENT_SECRET,
  REPLACEMENT_KEYS,
  RATES,
  ROLES,
  API_KEY,
  STATUSES,
  UPLOAD_LOCATIONS,
  EXPORT_LOCATIONS_URL,
  UPLOAD_TEAM_USERS,
  UPLOAD_COMPETITORS,
  EXPORT_TEMPLATES_URL,
  EXPORT_RATINGS_AND_REVIEWS,
  EXPORT_COMPETITOR_REPORT,
  EXPORT_RESPONSES_RATE_URL,
  INVITE_TEAM_USER,
  HELP_URL,
  COMPANY_TYPES,
  PARTNERS_TYPE,
  PARTNERS_PACKAGES,
  EXPORT_COMPANIES_URL,
  REVIEW_OPTIONS,
  EXPORT_OVERVIEW_REPORT_URL,
  EXPORT_INSIGHTS_REPORT_URL,
  EXPORT_LOCATION_RANKING_URL,
  EXPORT_COMPETITORS_URL,
  EXPORT_REVIEWS_URL,
  EXPORT_REVIEWS_TOPDF_URL,
  EXPORT_FEEDBACK_ANALYTICS_URL,
  EXPORT_DASHBOARD_URL,
  EXPORT_TEAM_ACCESS,
  FORGOT_PASSWORD_URL,
  EXPORT_LOCATIONS_EXAMPLE_URL,
  EXPORT_CSDASHBOARD_OVERVIEW,
  EXPORT_CSDASHBOARD_RESPONSE,
  EXPORT_CSDASHBOARD_FEEDBACK,
  EXPORT_CSDASHBOARD_OAUTH,
  EXPORT_MESSAGES_ANALYTICS_URL,
  EXPORT_MESSAGES_PAGES_URL,
  FEEDBACK_API_REFFERENCE,
  CONNECT_TO_GOOGLE_ACCOUNT,
  INFORMATION_REQUEST_API,
  INFORMATION_REQUEST_URL,
  TEST_URL,
  RANKS,
  EXPORT_PARTNERS_URL,
  EXPORT_PARTNERS_BILLING,
  SENTIMENT_OPTIONS,
  USERS,
  UNDER_MAINTENANCE,
  EXPORT_REVIEWS_EXAMPLE_URL,
  UPLOAD_REVIEWS,
  UPLOAD_COUPONS,
  INSIGHTS_CATEGORIES_DATA,
  EXPORT_COUPONS_EXAMPLE_URL,
  EXPORT_COMPETITORS_EXAMPLE_URL,
  GENERATE_COUPONS,
  EXPORT_OFFERS_EXCEL,
  EXPORT_COUPONS_EXCEL
};
