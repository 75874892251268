import React from 'react';
import { Tabs, Button, Icon, Popover } from 'antd';
import classNames from 'classnames';
import { COUNTRIES, LINK_FOR_CANADA_AND_USA, LINK_FOR_MIDDLE_EAST_COUNTRY, LINK_FOR_OTHER_COUNTRY, CANADA, USA } from '../../constants/Country';

import style from './style.module.less';

const AccountsSettings = match => {
  const onClickPortal = () => {
    if (match.currentUser.country === CANADA || match.currentUser.country === USA) {
      window.open(LINK_FOR_CANADA_AND_USA, '_blank');
    }
    if (COUNTRIES.includes(match.currentUser.country)) {
      window.open(LINK_FOR_MIDDLE_EAST_COUNTRY, '_blank');
    }
    if (match.currentUser.country !== CANADA && !COUNTRIES.includes(match.currentUser.country)) {
      window.open(LINK_FOR_OTHER_COUNTRY, '_blank');
    }
  };

  const content = (
    <p className={classNames(style.contentPopOver)}>You will be redirected to a secure site to enter the email address used for billing.</p>
  );
  return (
    <div className={style.userSettingsMain}>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Accounts Settings" key="1">
          <div className={style.title}>
            <p className={style.welcomeUser}>
              {' '}
              You can update your payment method, view invoices and receipts and update your company information including your tax ID.
            </p>
          </div>
          <div className={style.title}>
            <Button className={classNames(style.addNewResponseButton)} type="primary" onClick={onClickPortal}>
              Billing Portal
            </Button>
            <Popover content={content} trigger="hover">
              <Icon type="question-circle-o" className={classNames(style.questionIcon)} />
            </Popover>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
export default AccountsSettings;
